const columns = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id'
  },
  {
    title: '角色名称',
    key: 'title',
    dataIndex: 'title'
  },
  {
    title: '备注',
    key: 'content',
    dataIndex: 'content'
  },
  {
    title: '创建时间',
    key: 'created_at',
    dataIndex: 'created_at'
  },
  {
      title: "操作",
      key: "action",
      width: '100px',
      scopedSlots: { customRender: "action" },
  },
];

export default columns;
